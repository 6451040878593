<template>
    <div>
        <a-spin :spinning="loading">
            <div class="ft20 cl-black cl-main ftw500">售后单详情详情</div>

            <div class="mt20">
                <div class="bg-w pd40" style="min-height: 800px;">

                    <div class="">
                        <div class="flex alcenter">
                            <div class="ml8">
                                <div class="flex alcenter">
                                    <div class="flex alcenter">
                                        <div class="ft14 ftw400 cl-info">售后编号：</div>
                                        <div class="ft14 ftw600 cl-main">{{order.refund_sn}}</div>
                                    </div>

                                    <div class="flex alcenter ml40">
                                        <div class="ft14 ftw400 cl-info">创单时间：</div>
                                        <div class="ft14 ftw600 cl-main">{{order.create_time}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt30">
                        <div class="mall-goods_order-detail-info">
                            <div class="flex ">
                                <div class="pl40" style="width: 33%;border-right: 1px solid #EBEDF5;">
                                    <div class="ft14 ftw500 cl-main">售后状态</div>
                                    <div class="ft20 ftw600 cl-theme mt16" v-if="order.status==1">申请中</div>
                                    <div class="ft20 ftw600 cl-theme mt16" v-if="order.status==2">已同意待退款</div>
                                    <div class="ft20 ftw600 cl-theme mt16" v-if="order.status==3">拒绝</div>
                                    <div class="ft20 ftw600 cl-theme mt16" v-if="order.status==4">退款完成</div>
                                </div>
                                <div class="" style="width: 33%;">
                                    <div class="flex" style="border-right: 1px solid #EBEDF5; ">
                                        <div class="ml40 text-over4">
                                            <div class="ft14 ftw500 cl-main text-over4">售后金额</div>
                                            <div class="mt16">
                                                <div class="flex">
                                                    <div>
                                                        <div class="flex alcenter" v-if="order.refund_integral>0">
                                                            <div class="ft14 ftw400 cl-info text-over4">积分：</div>
                                                            <div class="ft14 ftw600 cl-main text-over4">
                                                                {{order.refund_integral}}</div>
                                                        </div>
                                                        <div class="flex alcenter">
                                                            <div class="ft14 ftw400 cl-info text-over4">现金金额：</div>
                                                            <div class="ft14 ftw600 cl-main text-over4">
                                                                ¥{{order.refund_price}}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="" style="width: 33%;" v-if="order.member">
                                    <div class="ml40">
                                        <div class="ft14 ftw500 cl-main">客户信息</div>
                                        <div class="mt16">
                                            <div class="flex">
                                                <div>
                                                    <div class="flex alcenter">
                                                        <div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
                                                        <div class="ft14 ftw600 cl-main text-over4">
                                                            {{order.member.nick_name}}</div>
                                                    </div>
                                                    <div class="flex alcenter mt10">
                                                        <div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
                                                        <div class="ft14 ftw600 cl-main text-over4">
                                                            {{order.member.mobile}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt30">
                        <div class="flex wrap">
                            <div class="mall-goods_order-detail-subcard-item">
                                <div class="flex  alcenter space">
                                    <div class="flex alcenter">
                                        <img v-if="order.project_cover_img != null" :src="order.project_cover_img" />
                                        <div class="ml10">
                                            <div class="ft14 ftw500 cl-info" >
                                                {{order.project_name}}
                                            </div>
                                            <div class="ft12 ftw400 cl-notice">{{order.project_sku_name}}</div>
                                        </div>
                                        <div class="flex alcenter ft14 ftw500 cl-info">
                                            <div class="ml8">x{{order.number}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex mt10">
                        <div class="flex wrap">
                          <template>
                            <a-timeline>
                              <a-timeline-item v-for="log in order.logs" :key="log.id">
                                {{log.channel_text}} {{log.create_time}}
                              </a-timeline-item>
                            </a-timeline>
                          </template>
                        </div>
                    </div>
                </div>
            </div>
        </a-spin>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                after_id: 0,
                order: {
                    refund_sn:'',

                }
            }
        },
        created() {
            if (!this.$route.query.id) {
                this.after_id = 0;
            } else {
                this.after_id = parseInt(this.$route.query.id);
            }
            this.loaddata();
        },
        methods: {
            loaddata() {
                if (this.loading == true) return;
                this.loading = true;
                this.$http.api('admin/projectOrderAfter/afterDetail', {
                    after_id: this.after_id
                }).then(res => {
                    this.order = res.detail;
                    this.loading = false;
                }).catch(res => {
                    console.log(res);

                    this.loading = false;
                    this.$router.go(-1);
                })
            },
            overGoodsAct(){
                this.$confirm({
                    title:'确认完成这个订单吗？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('admin/overGoodsOrder',{
                                order_id:this.order_id,
                            }).then(res=>{
                                this.$message.success('完成成功');
                                this.order.status=8;
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },
            deliverGoodsAct(){
                this.$confirm({
                    title:'确认发货这个订单吗？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('admin/deliverGoodsOrder',{
                                order_id:this.order_id,
                            }).then(res=>{
                                this.$message.success('发货成功');
                                this.order.status=3;
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },

            writeOffAct(){
                this.$confirm({
                    title:'确定核销这个订单吗？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('admin/writeOffOrder',{
                                order_id:this.order_id,
                            }).then(res=>{
                                this.$message.success('核销成功');
                                this.order.status=8;
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },
        }
    }
</script>

<style>
    .mall-goods_order-detail-tag {
        padding: 1px 8px;
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
    }

    .mall-goods_order-detail.tag {
        padding: 1px 4px;
        background: rgba(71, 114, 255, 0.1);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #4772FF;
    }


    .mall-goods_order-detail-info {
        background: #F9F9F9;
        border: 1px solid #EBEDF5;
        padding: 24px 0px;
    }

    .mall-goods_order-detail-subcard-item {
        width: 100%;
        height: 100px;
        background: #FFFFFF;
        border: 1px solid #EBEDF5;
        margin-bottom: 20px;
        padding: 20px;
    }

    .mall-goods_order-detail-subcard-item img {
        width: 75px;
        height: 60px;
    }

    .button-deliver {
        width: 96px;
        height: 40px;
        background: #4772FF;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }
</style>
